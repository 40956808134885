<template>
  <div>
    <b-form-group
      label="Tedarikçi"
      :label-for="'id_suppliers_' + itemKey"
    >
      <v-select
        :id="'id_suppliers_' + itemKey"
        v-model="dataItem[itemKey].id_suppliers"
        :options="suppliers"
        label="company"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      />

    </b-form-group>
  </div>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import { localize } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import vSelect from 'vue-select'

export default {
  name: 'SuppliersCard',
  components: {
    vSelect,
    BFormGroup,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
    suppliers() {
      return this.$store.getters['suppliers/dataList']
    },
  },
  created() {
    localize('tr')
    this.getSuppliers()
  },
  methods: {
    getSuppliers() {
      this.$store.dispatch('suppliers/getDataList', {
        select: [
          'suppliers.id as id',
          'suppliers.company as company',
        ],
      })
    },
  },
}
</script>
