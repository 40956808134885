<template>
  <div>
    <b-form-group
      label="Stok Kartı"
      :label-for="'id_stocks_' + itemKey"
    >
      <validation-provider
        #default="{ errors }"
        name="Stok Kartı"
        rules="required"
      >
        <v-select
          :id="'id_stocks_' + itemKey"
          v-model="dataItem[itemKey].id_stocks"
          :options="stocks"
          label="title"
          :reduce="item => item.id"
          placeholder="Seçiniz"
          @input="changedStock(dataItem[itemKey].id_stocks)"
        >
          <template v-slot:option="option">
            {{ option.title }}
            <span>
              <small class="text-info">{{ option.amount }} {{ option.units }}</small>
            </span>
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import vSelect from 'vue-select'

export default {
  name: 'StocksCard',
  components: {
    vSelect,
    ValidationProvider,
    BFormGroup,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
    stocks() {
      return this.$store.getters['stocks/dataList']
    },
    stockData() {
      return this.$store.getters['stocks/dataItem']
    },
  },
  created() {
    localize('tr')
    this.getStocks()
  },
  methods: {
    getStocks() {
      this.$store.dispatch('stocks/getDataList', {
        select: [
          'stocks.id as id',
          'stocks.title as title',
          'units.title as units',
        ],
      })
    },
    changedStock(idStocks) {
      if (idStocks) {
        this.getStockData(idStocks)
        this.dataItem[this.itemKey].units = this.stockData.units
        this.dataItem[this.itemKey].price = this.stockData.amountPrice
      }
    },
    getStockData(stockId) {
      this.$store.dispatch('stocks/getDataItem', stockId)
    },
  },
}
</script>
