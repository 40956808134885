<template>
  <div>

    <validation-observer ref="simpleRulesEntry">
      <b-card>
        <b-row>
          <b-col>
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-info"
                >
                  <feather-icon
                    size="24"
                    icon="BoxIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ project.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Proje Kartı İmalat Stok Çıkışı
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card>
      <div
        v-for="(key,index) in dataItem"
        :key="index"
      >
        <b-card
          no-body
        >
          <b-card-header>
            <b-card-title>{{ (index + 1) }}.Kayıt</b-card-title>
            <b-button
              variant="outline-danger"
              class="btn-icon rounded-circle"
              @click="removeRow(index)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-card-header>
          <b-card-body>
            <quick-stock-line-form :item-key="index" />
          </b-card-body>
        </b-card>
      </div>
      <b-row>
        <b-col
          class="text-left"
        >
          <b-button
            variant="primary"
            class="text-left"
            :disabled="dataItem.submitStatus"
            @click="EntrySubmitData"
          >
            <feather-icon icon="SaveIcon" />

            Kaydet
          </b-button>
        </b-col>
        <b-col class="float-right">
          <b-button
            variant="success"
            class="text-left ml-2 float-right"
            @click="addRow()"
          >
            <feather-icon icon="PlusIcon" />
            Yeni Satır
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BMediaBody, BMediaAside, BMedia, BAvatar, BCardText,
} from 'bootstrap-vue'
import QuickStockLineForm from '@/views/Admin/Stocks/Components/ProjectQuickStockOutLineForm.vue'
import vSelect from 'vue-select'

export default {
  name: 'QuickStocksAddLines',
  components: {
    BCardText,
    vSelect,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    QuickStockLineForm,
    BButton,
    ValidationObserver,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
  },
  data() {
    return {
      entry: null,
    }
  },
  computed: {
    stockData() {
      return this.$store.getters['stocks/dataItem']
    },
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
    saveData() {
      return this.$store.getters['stockLines/dataSaveStatus']
    },
    project() {
      return this.$store.getters['projects/dataItem']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.dataItem.submitStatus = false
        this.$store.commit('stockLines/RESET_DATA_ITEM_QUICK')
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('stockLines/RESET_DATA_ITEM_QUICK')
    this.dataItem[0].id_projects = this.$route.params.id_projects
    this.getProject()
  },
  methods: {
    EntrySubmitData() {
      this.$refs.simpleRulesEntry.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.$store.dispatch('stockLines/batchInsert', this.dataItem)
        } else {
          this.showToast({
            variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: 'Tüm alanları doldurmalısınız.',
          })
        }
      })
    },
    addRow() {
      this.dataItem.push({
        id: null,
        entry_amount: null,
        output_amount: null,
        price: null,
        invoice_number: null,
        id_projects: this.$route.params.id_projects,
        id_warehouses: null,
        id_suppliers: null,
        id_stocks: null,
        id_users: null,
        submitData: false,
      })
    },
    removeRow(key) {
      if (this.dataItem.length > 1) {
        this.dataItem.splice(key, 1)
      } else {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: 'Daha fazla satır silemezsiniz.',
        })
      }
    },
    getStockData(stockId) {
      this.$store.dispatch('stocks/getDataItem', stockId)
    },
    getProject() {
      this.$store.dispatch('projects/getDataItem', this.$route.params.id_projects)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
