<template>
  <div>
    <b-form-group
      label="Depo"
      :label-for="'id_warehouses_' + itemKey"
    >
      <validation-provider
        #default="{ errors }"
        name="Depo"
        rules="required"
      >
        <v-select
          :id="'id_warehouses_' + itemKey"
          v-model="dataItem[itemKey].id_warehouses"
          :options="warehouses"
          label="title"
          :reduce="item => item.id"
          placeholder="Seçiniz"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import vSelect from 'vue-select'

export default {
  name: 'WarehousesCard',
  components: {
    vSelect,
    ValidationProvider,
    BFormGroup,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
    warehouses() {
      return this.$store.getters['warehouses/dataList']
    },
  },
  created() {
    localize('tr')
    this.getWarehouses()
  },
  methods: {
    getWarehouses() {
      this.$store.dispatch('warehouses/getDataList')
    },
  },
}
</script>
